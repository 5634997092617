import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            {" "}
            <img src="img/logo.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="oscar">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "about us"}</p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d.text}-${i}`}><div style={{fontWeight : "bold", marginBottom : "10px", marginTop : "10px"}}>{d.label}</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{d.text}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
